// src/components/ui/alert.js
import React from 'react';

// Default Alert component
const Alert = ({ variant = "default", children }) => {
    const variantClass = variant === 'destructive' ? 'bg-red-500 text-white' : 'bg-gray-100 text-gray-800';
    return (
        <div className={`alert ${variantClass} p-4 rounded`}>
            {children}
        </div>
    );
};

// Named export for AlertDescription
export const AlertDescription = ({ children }) => (
    <p className="alert-description">{children}</p>
);

// Export the Alert component as default
export default Alert;
